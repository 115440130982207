import React from 'react';
import {Helmet} from 'react-helmet-async';

import MarketLayout, {reducer as marketLayoutReducer} from 'web/components/market_layout';
import MarketSidebar from 'web/components/market_sidebar';
import {SeparatorLine} from 'web/components/separator';
import {PageType} from 'web/helpers/redux_client';
import DeliveryInfo from 'web/how_it_works/components/delivery_info';
import HowItWorks from 'web/how_it_works/components/how_it_works';
import ShopWithUs from 'web/how_it_works/components/shop_with_us';

import {StoreData} from './controller';

const NAV_LIST_ITEMS: Array<{slug: string; label: string}> = [
  {
    slug: 'how-it-works',
    label: 'How It Works',
  },
  {
    slug: 'delivery-info',
    label: 'Delivery Info',
  },
  {
    slug: 'shop-with-us',
    label: 'Why Shop With Us',
  },
];

const HowItWorksMarketSideBar: React.FC = () => {
  const menuGroups = NAV_LIST_ITEMS.map(({slug, label}) => ({
    displayName: label,
    id: slug,
    menuItems: [],
  }));
  return (
    <div className="how-it-works__sidebar">
      <MarketSidebar menuGroups={menuGroups} anchorAtContainer={false} />
    </div>
  );
};

const HowItWorksPage: PageType<StoreData> = () => {
  return (
    <MarketLayout disableUpcomingOrdersBanner>
      <Helmet>
        <title>How It Works | Good Eggs</title>
      </Helmet>

      <div className="how-it-works-page__content">
        <div className="how-it-works-page__content-inner">
          <HowItWorksMarketSideBar />
          <div className="how-it-works-page__column-content">
            <div>
              <div className="how-it-works-page__section-anchor" id={NAV_LIST_ITEMS[0].slug} />
              <HowItWorks />
            </div>
            <SeparatorLine />
            <div>
              <div className="how-it-works-page__section-anchor" id={NAV_LIST_ITEMS[1].slug} />
              <DeliveryInfo />
            </div>
            <SeparatorLine />
            <div>
              <div className="how-it-works-page__section-anchor" id={NAV_LIST_ITEMS[2].slug} />
              <ShopWithUs />
            </div>
          </div>
        </div>
      </div>
    </MarketLayout>
  );
};

HowItWorksPage.pageName = 'How It Works';
HowItWorksPage.reducer = (state, action) => {
  // TODO: (@shermam) This is not ideal, but the state should always be set here
  // since we preload it from the controller. We should maybe come up with a better
  // way of asserting the type here
  if (!state) throw new Error('State should always be preloaded here');

  return marketLayoutReducer(state, action);
};

export default HowItWorksPage;
