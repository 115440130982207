import React, {FC} from 'react';

import assetPath from 'web/helpers/asset_path';

const ShopWithUs: FC = () => {
  return (
    <div className="how-it-works-page__content how-it-works-page__shop_with_us">
      <div className="how-it-works-page__shop_with_us__tagline">
        <h1>Why Shop With Us</h1>
        <p className="subtext">
          We’re pioneering a new way to bring food to your table straight from the people who grow
          it, raise it, and make it.
        </p>
      </div>
      <div className="how-it-works-page__shop_with_us__footer">
        <div className="how-it-works-page__shop_with_us__footer__reason">
          <div className="how-it-works-page__shop_with_us_icon_container">
            <img
              className="how-it-works-page__shop_with_us_icon"
              src={`${assetPath('/img/web/how_it_works/no_one_fresher.png')}?auto=format`}
            />
          </div>
          <h3>The freshest food</h3>
          <p>
            Because we work directly with local producers in the Bay Area, we’re able to bring you
            the freshest groceries you’ve ever tasted. Picked yesterday, caught last night, baked
            this morning.
          </p>
        </div>
        <div className="how-it-works-page__shop_with_us__footer__reason">
          <div className="how-it-works-page__shop_with_us_icon_container">
            <img
              className="how-it-works-page__shop_with_us_icon"
              src={`${assetPath('/img/web/how_it_works/no_one_higher_standards.png')}?auto=format`}
            />
          </div>
          <h3>The highest standards</h3>
          <p>
            We vet every product we sell against the strictest standards in the industry. We’re
            looking for 100% supply chain transparency, ingredients we feel good about, and
            sustainable and humane practices.
          </p>
        </div>
        <div className="how-it-works-page__shop_with_us__footer__reason">
          <div className="how-it-works-page__shop_with_us_icon_container">
            <img
              className="how-it-works-page__shop_with_us_icon"
              src={`${assetPath('/img/web/how_it_works/no_one_more_convenient.png')}?auto=format`}
            />
          </div>
          <h3>The most convenient</h3>
          <p>
            Convenience isn’t just about skipping the grocery store. It means having a simple,
            trusted way to feel good about the food you’re eating every day. You can rely on us to
            deliver (in more ways than one).
          </p>
        </div>
      </div>
    </div>
  );
};

export default ShopWithUs;
