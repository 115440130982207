import React, {FC} from 'react';

import assetPath from 'web/helpers/asset_path';

const DeliveryInfo: FC = () => {
  return (
    <div className="how-it-works-page__delivery_info how-it-works-page__content">
      <div className="how-it-works-page__delivery_info__title">Delivery Details</div>
      <div className="how-it-works-page__delivery_info__explanations">
        <div className="how-it-works-page__delivery_info__explanation">
          <img
            className="how-it-works-page__delivery_info__icon"
            src={`${assetPath('/img/web/how_it_works/delivery_instructions.png')}?auto=format`}
          />
          <h3>Add instructions</h3>
          <p>
            You can add detailed delivery instructions. Should we ring the doorbell? Bring your
            delivery around back? Let us know and we’ll follow through.
          </p>
        </div>
        <div className="how-it-works-page__delivery_info__explanation">
          <img
            className="how-it-works-page__delivery_info__icon mask"
            src={`${assetPath('/img/web/how_it_works/mask_larger.png')}?auto=format`}
          />
          <h3>Convenient Delivery Windows</h3>
          <p>
            Choose a time of day that works for your schedule—including same-day afternoon delivery
            for most delivery zones.
          </p>
        </div>
        <div className="how-it-works-page__delivery_info__explanation">
          <img
            className="how-it-works-page__delivery_info__icon"
            src={`${assetPath('/img/web/how_it_works/packaged_sustainably.png')}?auto=format`}
          />
          <h3>Packaged sustainably</h3>
          <p>
            All of our packaging is designed with sustainability in mind. We’ll even pick up your
            boxes and ice packs to reuse or recycle them.
          </p>
        </div>
      </div>
      <div className="how-it-works-page__delivery_info__caption">
        For more information on fees, visit our{' '}
        <a href="https://help.goodeggs.com/hc/en-us/articles/360027609972-Does-Good-Eggs-charge-a-delivery-fee-">
          Help Center
        </a>
        .
      </div>
    </div>
  );
};

export default DeliveryInfo;
