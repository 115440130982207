import React, {FC} from 'react';

import assetPath from 'web/helpers/asset_path';

const HowItWorks: FC = () => {
  return (
    <div className="how-it-works-page__how-it-works how-it-works-page__content">
      <div className="how-it-works-page__how-it-works__header">How It Works</div>
      <div className="how-it-works-page__how-it-works__description">
        Good Eggs is a pioneering online grocery service delivering to families throughout the Bay
        Area and Los Angeles. Order in the morning on our website or iOS/Android app, and absurdly
        fresh groceries will be at your door tonight.
      </div>
      <div className="how-it-works-page__how-it-works__steps">
        <div className="how-it-works-page__how-it-works__step">
          <img
            className="how-it-works-page__how-it-works__step__image-shop-the-market"
            src={`${assetPath('/img/web/how_it_works/shop_the_marketplace.png')}?auto=format`}
          />
          <div className="how-it-works-page__how-it-works__step__description">
            <h3 className="how-it-works-page__how-it-works__step__description__header">
              1. Shop the Marketplace
            </h3>
            <p className="how-it-works-page__how-it-works__step__description__text">
              We have everything you need: grocery staples, meal kits, and household essentials that
              you can shop for seamlessly.
            </p>
          </div>
        </div>
        <div className="how-it-works-page__how-it-works__step">
          <img
            className="how-it-works-page__how-it-works__step__image-pick-delivery-time"
            src={`${assetPath('/img/web/how_it_works/delivery_time.png')}?auto=format`}
          />
          <div className="how-it-works-page__how-it-works__step__description">
            <h3 className="how-it-works-page__how-it-works__step__description__header">
              2. Pick a Delivery Time
            </h3>
            <p className="how-it-works-page__how-it-works__step__description__text">
              We offer flexible delivery windows, seven days a week. We can leave your order even if
              you’re not home.
            </p>
          </div>
        </div>
        <div className="how-it-works-page__how-it-works__step">
          <img
            className="how-it-works-page__how-it-works__step__image-get-it-tomorrow"
            src={`${assetPath('/img/web/how_it_works/get_it_today.png')}?auto=format`}
          />
          <div className="how-it-works-page__how-it-works__step__description">
            <h3 className="how-it-works-page__how-it-works__step__description__header">
              3. Get It Today
            </h3>
            <p className="how-it-works-page__how-it-works__step__description__text">
              Our team will pick your items and pack them carefully into our recyclable boxes. In a
              few hours your order will be at your door.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
